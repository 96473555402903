const simulatorVersion = process.env.SIMULATOR_VERSION;

export const config = (buildNumber?: string) => {
  const buildUrl = `https://storage.googleapis.com/techdisc-cdn/unity/${buildNumber ?? simulatorVersion}/Simulator/Build/Simulator`;
  return {
    loaderUrl: `${buildUrl}.loader.js`,
    dataUrl: `${buildUrl}.data`,
    frameworkUrl: `${buildUrl}.framework.js`,
    codeUrl: `${buildUrl}.wasm`,
    streamingAssetsUrl: `https://storage.googleapis.com/techdisc-cdn/unity/${buildNumber ?? simulatorVersion}/Simulator/StreamingAssets`,
    companyName: "TechDisc Inc.",
    productName: "TechDisc",
    productVersion: "0.1.0",
  };
};

export const mobileConfig = (buildNumber?: string) => {
  const mobileBuildUrl = `https://storage.googleapis.com/techdisc-cdn/unity/${buildNumber ?? simulatorVersion}/MobileSimulator/Build/MobileSimulator`;
  return {
    loaderUrl: `${mobileBuildUrl}.loader.js`,
    dataUrl: `${mobileBuildUrl}.data`,
    frameworkUrl: `${mobileBuildUrl}.framework.js`,
    codeUrl: `${mobileBuildUrl}.wasm`,
    streamingAssetsUrl: `https://storage.googleapis.com/techdisc-cdn/unity/${buildNumber ?? simulatorVersion}/Simulator/StreamingAssets`,
    companyName: "TechDisc Inc.",
    productName: "TechDisc",
    productVersion: "0.1.0",
  };
};

/**
 * Type declaration for the UnityInstance.
 */
export interface UnityInstance {
  /*
   * Sends a message to the UnityInstance to invoke a public method.
   * @param gameObjectName the name of the game object in your Unity scene.
   * @param methodName the name of the public method on the game object.
   * @param parameter an optional parameter to pass along to the method.
   */
  SendMessage(gameObjectName: string, methodName: string, parameter?: any): void;

  /**
   * Enables or disabled the fullscreen mode of the UnityInstance.
   * @param fullScreen sets the fullscreen mode.
   */
  SetFullscreen(fullScreen: boolean): void;

  /**
   * Quits the Unity WebGL application and removes it from the memory.
   * @returns a promise which resolves when the application did quit.
   */
  Quit(): Promise<void>;
}
